<template>
  <div>
    <el-form-item label="行动号召" prop="actionTypeList">
      <span slot="label">
        <span>行动号召</span>
        <el-popover
          placement="top-start"
          title=""
          width="200"
          trigger="hover"
          content="广告展示给用户时，会展示一个明确可点击的按钮，可以选择不用的文案。"
        >
          <i class="el-icon-info" slot="reference" style="margin-left: 5px"></i>
        </el-popover>
      </span>
      <el-row
        v-for="(actionType, index) in actionTypeList"
        :key="index"
        style="margin-bottom: 10px"
      >
        <el-col :span="10">
          <el-select
            placeholder="请选择行动号召"
            v-if="initData.ad"
            v-model="actionTypeList[index]"
            style="width: 96%"
            @change="emitAction"
            :class="[actionTypeList.filter(v=>v == actionTypeList[index]).length>=2?'redBorder':'']"
          >
            <el-option
              :label="action.desc"
              :value="action.key"
              v-for="action in initData.ad.CallToActionType"
              :key="action.key"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="6"
          ><el-button
            icon="el-icon-minus"
            type="text"
            @click="deleItem('actionTypeList', index)"
            v-if="actionTypeList.length > 1"
          ></el-button>
          <el-button
            icon="el-icon-plus"
            type="text"
            @click="addItem('actionTypeList')"
            v-if="
              index == actionTypeList.length - 1 && actionTypeList.length < 5
            "
          ></el-button
        ></el-col>
      </el-row>
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    linkHistory: {
      type: Array,
      default: () => [],
    },
    initData: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      actionTypeList: [],
    };
  },
  methods: {
    // 添加
    addItem(type) {
      if (type == "webLinkList") {
        this[type].push({
          url: "",
          checkStatus: {
            message:
              "请输入完整的网址，需包含https或者http。例如：https://www.giikin.com/页面路径…",
            level: "error",
          },
          checkLoading: false,
        });
      } else {
        this[type].push("");
      }
      this.emitAction();
    },
    // 删除
    deleItem(type, index) {
      this[type].splice(index, 1);
      if (this[type][index]) {
        console.log(222);
        this.emitAction();
      }
    },
    emitAction() {
      this.$emit("input", this.actionTypeList);
    },
  },
  watch: {
    value: {
      handler() {
        this.actionTypeList = JSON.parse(JSON.stringify(this.value));
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.el-select{
  &.redBorder{
    .el-input__inner,.el-textarea__inner{
      border:1px solid #ff0000 !important;
    }
    
  }
}
</style>