<template>
  <div>
    <action-select v-model="actionTypeList" :initData="initData" @input="emitCreative"></action-select>
    <web-link v-model="webLinkList" :linkHistory="linkHistory" @input="emitCreative"></web-link>
  </div>
</template>

<script>
import adsMethods from "../../adsMethods.js";
import webTips from "../webTips";
import webLink from "./webLink.vue"
import actionSelect from "./actionSelect.vue"
export default {
  mixins: [adsMethods],
  components: {
    webTips,
    webLink,
    actionSelect
  },
  props: {
    linkHistory: {
      type: Array,
      default: () => [],
    },
    initData: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      actionTypeList: [""],
      webLinkList: [
        {
          url: "",
          checkStatus: {
            message:
              "请输入完整的网址，需包含https或者http。例如：https://www.giikin.com/页面路径…",
            level: "error",
          },
          checkLoading: false,
        },
      ],
      linkIndex: 0,
    };
  },
  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.linkHistory;
      var results = queryString
        ? restaurants.filter((n) => {
            return n.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
          })
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    // 输入网址校验
    selectwebLink(v) {
      // this.checkLoading = true;
      console.log(v);
      this.onInputWebLink(v, this.linkIndex);
      this.$set(this.webLinkList[this.linkIndex], 'url', v);
      this.webLinkList[this.linkIndex].url = v;
      console.log(this.webLinkList[this.linkIndex].url);
    },
    onInputWebLink(v, index) {
      console.log(v);
      if (!v) return false;
      this.webLinkList[this.linkIndex].checkLoading = true;
      websiteUrlCheck({ accountId: this.$store.getters.accountId, siteUrl: v }).then((res) => {
        // console.log(res);
        this.webLinkList[this.linkIndex].checkLoading = false;
        if (res.code == 0) {
          this.webLinkList[this.linkIndex].checkStatus = res.data;
          this.emitCreative();
        }
      });
      
    },
    // 添加
    addItem(type) {
      if (type == "webLinkList") {
        this[type].push({
          url: "",
          checkStatus: {
            message:
              "请输入完整的网址，需包含https或者http。例如：https://www.giikin.com/页面路径…",
            level: "error",
          },
          checkLoading: false,
        });
      } else {
        this[type].push("");
      }
      this.emitCreative()
    },
    // 删除
    deleItem(type, index) {
      this[type].splice(index, 1);
      if (this[type][index]) {
        this.emitCreative();
      }
    },
    emitCreative() {
      let creative = JSON.parse(JSON.stringify(this.value));
      let fbAssetFeedSpec = {};
      fbAssetFeedSpec.actionTypes = this.actionTypeList.filter(v=>v).length?this.actionTypeList:[''];
      console.log(this.webLinkList);
      fbAssetFeedSpec.linkUrls = this.webLinkList
      creative.fbAssetFeedSpec = {
        ...creative.fbAssetFeedSpec,
        ...fbAssetFeedSpec,
      };
      this.$emit("input", creative);
    },
  },
  watch: {
    initData: {
      handler() {
        console.log(this.initData);
      },
    },
    value:{
      handler(){
        console.log(this.value);
        this.actionTypeList = this.value.fbAssetFeedSpec.actionTypes;
        let list = [];
        let links = this.value.fbAssetFeedSpec.linkUrls ||this.value.fbAssetFeedSpec.links
        // links.map((v) => {
        //   list.push({
        //     url: v.url?v.url:v,
        //     checkStatus: {
        //       message:"",
        //       level: "ok",
        //     },
        //     checkLoading: false,
        //   });
        // });
        this.webLinkList = [...links]
      },
      deep:true,
      immediate:true
    },
    actionTypeList:{
      handler(){
        // this.emitCreative();
      },
      deep:true,
    },
    webLinkList:{
      handler(){
        // this.emitCreative();
      },
      deep:true,
    }
  },
};
</script>

<style lang="scss" scoped>
.tipsColor {
  font-size: 12px;
  color: #666;
  &.error {
    color: #f56c6c;
  }
  &.warn {
    color: #e6a23c;
  }
  &.ok {
    color: #67c23a;
  }
}
</style>