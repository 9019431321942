<template>
  <div>
    <el-form
      :model="form"
      label-width="120px"
      label-suffix="："
    >
      <el-form-item label="进阶受众">
        <el-checkbox
          v-model="isAdvanced"
          :true-label="1"
          :false-label="0"
          >使用进阶赋能型受众
        </el-checkbox>

        <el-tooltip
          placement="top"
          effect="light"
        >
          <div slot="content">
            <p style="font-size: 14px; font-weight: bold; margin: 10px 0">使用进阶赋能型受众来改善表现</p>
            <div>
              让我们先进的广告技术自动为这个广告系列寻找受众，以便帮助改善表现。你仍可以设置限制，以控制谁能看到你的广告。
            </div>
            <p style="font-size: 14px; font-weight: bold; margin: 10px 0">可能的结果</p>
            <div>高达 33%的单次成效费用降低</div>
          </div>
          <i
            class="el-icon-info"
            style="margin-left: 5px; color: #666"
          ></i>
        </el-tooltip>
        <span class="tips"
          >(启用进阶赋能型受众后，广告组的最小年龄必须 18 至 25 之间；最大年龄必须将其设置为 65。)</span
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    isAdvanced: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.tips {
  font-size: 12px;
  color: #999;
  margin-left: 10px;
}
</style>
